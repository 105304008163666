import React from 'react';

export default function Foot(){
  return(
    <div id="foot">
    <div id="footlogo"><h3>Pinyon</h3></div>
    <div className="content" style={{ lineHeight: "2rem" }}>
      <h4>Company</h4>
      <ul style={{ listStyle: "none" }}>
        <li>About</li>
        <li>Contact Us</li>

      </ul>
    </div>
    <div className="content" style={{ lineHeight: "2rem" }}>
      <h4>Legal</h4>
      <ul style={{ listStyle: "none" }}>
        <li>Terms of Use</li>
        <li>Privacy Policy</li>
      </ul>
    </div>
  </div>
  )
}