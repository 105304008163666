import React from 'react';
import Foot from './Footer'

function App() {
  return (
    <>
      <div className="parallax" id="topTree">
        <h1 style={{ textAlign: "center" }}>Pinyon Web Development</h1>
      </div>


      <div className="content" id="one">
        <p>You want a web presence…but how do you make that happen? There are free services out there that allow you to use a preset layout with your images and content. There are even ones that allow you to customize the raw code within their interface. But they often lack that which makes a site seem professional.</p>
        <ul>
          <li>The address for the website may contain the URL of the hosting company.</li>
          <li>Page layout may be perfect in one screen width, for instance a laptop screen, but it was not built for mobile.</li>
          <li>The content may not take into account assistive technologies such as screen readers for the blind or alternative input devices for those who cannot use a keyboard or a mouse.</li>
        </ul>
        <h2>Domain Name Ownership</h2>
        <p>You should own your domain! Not your hired web developer or your hosting company. At Pinyon we guide you in setting that up. If you ever wish to separate from us, we would not have access to control your domain. It's better for you and removes some liability from us.</p>
        <p>I personally recommend <a href="https://hover.com/UPTIC7UZ" target="_blank" rel="noopener noreferrer">Hover</a> as a domain registrar because they include hiding your Whois info in the price. The other major players out there have a similar ability, but you'll pay extra for it.</p>
        <details>
          <summary>What is the Whois database?</summary>
          <p>Whois is a way to lookup information about a domain's owner. It contains your name, address, email, and your phone number as you gave it to your registrar. They are required to record it for <abbr title="Internet Corporation for Assigned Names and Numbers">ICANN</abbr> or they lose the ability to sell domain names. <strong>The Whois database is publicly accessible!</strong> If you already have a domain look it up at <a href="https://www.whois.com/whois" target="_blank" rel="noopener noreferrer">https://www.whois.com/whois</a> and see what info is out there.</p>
          <p>Hover still records the information, but uses their own proxy info for Whois. This removes your private info from the public whois database while giving law enforcement the ability to find out who owns a domain name via court order.</p>
        </details>

    
        <h2>Responsive Layout</h2>
        <p>For years web designers and developers tried to create sites the were good on the desktop and were somewhat functional on mobile. Turns out it's easier to do it the other way round. Pinyon's layout team works from a mobile first prospective. We use the latest CSS standards to create content that looks good on any screen. Commonly this involves Flexbox and CSS Grid.</p>

        <h2>Accessibility</h2>
        <p>100% of our staff fall somewhere on the Autistic Spectrum. We know what it is like to not have access to things most people do for whatever reason. For every project we take on, we strive to make it as accessible as possible. This has the added benefit of making the projects more friendly for SEO right from the start.</p>

        <h2>Hosting Your Content</h2>
        <p>With the most well know free host out there you get an address that contains the name of the hosting service. You can usually point your domain name to that address using a CNAME so visitors to your your site only see your address. Here is what you miss out on with most of those service.</p>
        <ul>
          <li>Git integration using services like Github that allow you to commit versions of your project. If you mess something up, Git will allow you to take a step back to any previous commit you pushed through. If you want to develop a new feature without it interfering with your current content, Git allows you to safely branch the project to work on the new stuff before you bring it in to your "production" code</li>
          <li>The ability to truly edit your content. The service forces you to use their <abbr title="What You See Is What You Get">WYSIWYG</abbr>. You simply cannot create something that looks good on mobile and desktop in a WISIWUG without using the "See Raw Code" feature. And even it limits what you can do. There is nothing as precise as editing the code in an <abbr title="Integrated Development Environment">IDE</abbr> and simply committing it to Git to deploy it safely.</li>
          <li>Easy use of a CDN. You want you custom domain to load quickly from anywhere in the world, you need a CDN. It provides some resilience in case one host goes down or gets overwhelmed</li>
        </ul>
      </div>



      <div className="parallax" id="cone">
        
      </div>


      <Foot />
    </>
  );
}

export default App;
